@layer components {
  .embla {
    overflow: hidden;

    &:not(.is-y-axis) {
      width: var(--gallery-size);
    }

    &.is-y-axis {
      height: var(--gallery-size);
    }

    &.is-not-filled {
      opacity: 0;
      transition: opacity theme('transitionDuration.DEFAULT')
        theme('transitionTimingFunction.DEFAULT');

      .embla-wrap.is-ready & {
        opacity: 1;
      }
    }
  }

  .embla-container {
    display: flex;
    touch-action: pan-y;

    .embla:not(.is-y-axis) & {
      width: 100%;
    }

    .embla.is-y-axis & {
      flex-direction: column;
      height: 100%;
      touch-action: pan-x;
    }

    .embla.is-auto-height & {
      align-items: flex-start;
      transition: height theme('transitionDuration.DEFAULT')
        theme('transitionTimingFunction.DEFAULT');
    }
  }

  .embla-slide {
    min-width: 0;
    padding-left: calc(var(--slide-spacing) / 2);
    padding-right: calc(var(--slide-spacing) / 2);
    user-select: none;

    .embla:not(.is-y-axis) & {
      flex: 0 0 var(--slide-size);
      max-width: 100%;
    }

    .embla.is-y-axis & {
      max-height: 100%;
    }

    .embla.has-class-names & {
      opacity: 0;
      transition: opacity 500ms theme('transitionTimingFunction.DEFAULT');

      &.is-in-view {
        opacity: 1;
      }
    }
  }

  /* optional element that wraps .embla for prev/next buttons */
  .embla-wrap {
    position: relative;

    &.is-y-axis {
      padding-bottom: var(--gallery-btn-size);
      padding-left: 0;
      padding-right: 0;
      padding-top: var(--gallery-btn-size);
    }

    &.absolute:not(.is-y-axis) {
      width: calc(var(--gallery-size) + var(--gallery-btn-size) * 2);
    }

    &.absolute.is-y-axis {
      height: calc(var(--gallery-size) + var(--gallery-btn-size) * 2);
    }
  }

  .embla-btn-prev,
  .embla-btn-next {
    --btn-bg-hover: theme('colors.green');
    height: var(--gallery-btn-size);
    position: absolute;
    width: var(--gallery-btn-size);
    z-index: 3;

    .embla-wrap:not(.is-y-axis) & {
      top: 50%;
    }

    .embla-wrap.is-y-axis & {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }

    &:not([disabled], [aria-disabled='true']) {
      pointer-events: auto;
    }
  }

  .embla-btn-prev {
    .embla-wrap:not(.is-y-axis) & {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    .embla-wrap.is-y-axis & {
      top: 0;
    }
  }

  .embla-btn-next {
    .embla-wrap:not(.is-y-axis) & {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }

    .embla-wrap.is-y-axis & {
      bottom: 0;
    }
  }

  /* for when slides need to be positioned initially */
  .embla-spinner {
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity theme('transitionDuration.DEFAULT')
      theme('transitionTimingFunction.DEFAULT');
    z-index: 2;

    .embla-wrap.is-ready & {
      opacity: 0;
    }
  }

  @screen md {
    .embla-wrap {
      &:not(.is-y-axis) {
        padding-left: var(--gallery-btn-size);
        padding-right: var(--gallery-btn-size);

        .embla-btn-prev,
        .embla-btn-next {
          transform: translate3d(0, -50%, 0);
        }
      }
    }
  }
}

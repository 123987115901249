@layer components {
  .cta,
  .cta-parent {
    border-radius: 0;
    user-select: none;
  }

  .cta,
  .cta-child {
    align-items: center;
    background-color: var(--btn-bg);
    border: theme('borderWidth.DEFAULT') solid var(--btn-bg);
    border-radius: var(--btn-roundness);
    color: var(--btn-fg);
    display: inline-flex;
    font-family: theme('fontFamily.sans');
    justify-content: center;
    min-height: var(--ui-size-min);
    min-width: var(--ui-size-min);
    padding: var(--ui-y, 0.15em) var(--ui-x, 0.75em);
    text-align: center;
    transition:
      background-color theme('transitionDuration.DEFAULT'),
      color theme('transitionDuration.DEFAULT');
  }

  .cta.large,
  .cta-parent.large .cta-child {
    height: var(--ui-size-lg);
    padding-left: var(--ui-x-lg);
    padding-right: var(--ui-x-lg);
  }

  .cta > *,
  .cta-parent > *,
  .cta-child > * {
    pointer-events: none;
  }

  .cta:focus-visible,
  .cta[data-headlessui-state='active'],
  .cta-parent:focus-visible .cta-child,
  .cta-parent[data-headlessui-state='active'] .cta-child {
    background-color: var(--btn-bg-hover);
    border-color: var(--btn-fg-hover);
    color: var(--btn-fg-hover);
  }

  .cta[aria-expanded='true'],
  .cta[aria-checked='true'],
  .cta-parent[aria-expanded='true'] .cta-child,
  .cta-parent[aria-checked='true'] .cta-child {
    background-color: var(--btn-bg-active);
    color: var(--btn-fg-active);
  }

  @media (hover: hover) and (pointer: fine) {
    .cta:hover,
    .cta-parent:hover .cta-child {
      background-color: var(--btn-bg-hover);
      border-color: var(--btn-fg-hover);
      color: var(--btn-fg-hover);
    }

    .cta[aria-expanded='true'],
    .cta[aria-checked='true'],
    .cta-parent[aria-expanded='true'] .cta-child,
    .cta-parent[aria-checked='true'] .cta-child {
      background-color: var(--btn-bg-active);
      color: var(--btn-fg-active);
    }
  }

  /* stylelint-disable no-descending-specificity */
  .cta[disabled],
  .cta[aria-disabled='true'],
  [aria-disabled='true'] .cta,
  .cta-parent[disabled] .cta-child,
  .cta-parent[aria-disabled='true'] .cta-child,
  [aria-disabled='true'] .cta-child {
    --btn-bg: theme('colors.white');
    --btn-fg: theme('colors.black');
    --btn-bg-hover: theme('colors.white') !important;
    --btn-fg-hover: theme('colors.black') !important;
    --btn-bg-active: theme('colors.white') !important;
    --btn-fg-active: theme('colors.black') !important;
    pointer-events: none;
  }
  /* stylelint-enable no-descending-specificity */
}

@layer components {
  .scheme,
  .scheme-contrast {
    background-color: var(--scheme-bg);
    color: var(--scheme-fg);
  }

  .scheme.green,
  .scheme.green .scheme.scheme-nested {
    --scheme-bg: theme('colors.green');
    --scheme-fg: theme('colors.yellow');
    --btn-bg: theme('colors.gold');
    --btn-fg: theme('colors.green');
    --btn-bg-hover: theme('colors.yellow');
    --btn-fg-hover: theme('colors.green');
    --color-accent: theme('colors.pink');
    --fig-border-outer-color: theme('colors.gold');
  }

  .scheme.blue,
  .scheme.blue .scheme.scheme-nested {
    --scheme-bg: theme('colors.blue');
    --scheme-fg: theme('colors.green');
    --color-accent: theme('colors.yellow');
  }

  .scheme.gold {
    --scheme-bg: theme('colors.gold');
    --scheme-fg: theme('colors.green');
    --color-accent: theme('colors.orange');
    --btn-bg: theme('colors.green');
    --btn-fg: theme('colors.yellow');
    --btn-bg-hover: theme('colors.yellow');
    --btn-fg-hover: theme('colors.green');
    --link-color: theme('colors.green');
  }

  .scheme.pink {
    --scheme-bg: theme('colors.pink');
    --scheme-fg: theme('colors.green');
    --color-accent: theme('colors.orange');
    --link-color: theme('colors.yellow');
    --link-hover: theme('colors.orange');
  }

  .scheme.green .scheme-contrast,
  .scheme.blue .scheme-contrast,
  .scheme.gold .scheme-contrast {
    --scheme-bg: theme('colors.pink');
    --scheme-fg: theme('colors.green');
    --color-accent: theme('colors.orange');
  }

  .scheme.pink .scheme-contrast {
    --scheme-bg: theme('colors.green');
    --scheme-fg: theme('colors.yellow');
    --color-accent: theme('colors.gold');
  }
}

@layer components {
  .textarea-wrap {
    background-color: var(--input-bg);
    border: var(--border-w) solid var(--input-fg);
    color: var(--input-fg);
    height: 8em;
    padding: var(--ui-y) calc(var(--ui-x) / 2);
    text-align: left;
    transition: border-color theme('transitionDuration.DEFAULT')
      theme('transitionTimingFunction.DEFAULT');
    width: 100%;

    textarea {
      background-color: rgba(255, 255, 255, 0.25);
      border: var(--border-w) solid transparent;
      border-radius: calc(var(--ui-x) * 2);
      height: 100%;
      padding: var(--ui-y) calc(var(--ui-x) * 2);
      transition: background-color theme('transitionDuration.DEFAULT')
        theme('transitionTimingFunction.DEFAULT');
      width: 100%;

      &::placeholder {
        color: var(--input-fg);
        text-transform: uppercase;
      }

      &:focus-visible {
        background-color: rgba(255, 255, 255, 0.33);
        border-color: var(--input-fg-hover);
        outline: none;
      }

      .textarea-wrap[aria-invalid='true'] & {
        border-color: theme('colors.red');
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: var(--input-fg-hover);
      }

      textarea:hover {
        background-color: rgba(255, 255, 255, 0.33);
      }
    }
  }
}

.button,
.button-parent {
  align-items: center;
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  min-height: var(--ui-size-min);
  min-width: var(--ui-size-min);
  user-select: none;
}

.button.large,
.button-parent.large {
  height: var(--ui-size-lg);
}

/* fix for custom cursor hover state */
.button > *,
.button-parent > *,
.button-child > * {
  pointer-events: none;
}

/* disabled */
/* stylelint-disable-next-line no-descending-specificity */
.button[disabled],
.button[aria-disabled='true'],
[aria-disabled='true'] .button,
.button-parent[disabled] .button-child,
.button-parent[aria-disabled='true'] .button-child,
[aria-disabled='true'] .button-child {
  --btn-bg: theme('colors.gray');
  --btn-fg: theme('colors.black');
  --btn-bg-hover: theme('colors.gray') !important;
  --btn-fg-hover: theme('colors.black') !important;
  --btn-bg-active: theme('colors.gray') !important;
  --btn-fg-active: theme('colors.black') !important;
  pointer-events: none;
}

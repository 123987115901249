@layer components {
  .link,
  .link-child,
  .svg-link,
  .svg-link-child {
    color: var(--link-color);
    transition: color theme('transitionDuration.DEFAULT')
      theme('transitionTimingFunction.DEFAULT');
  }

  .link:focus-visible,
  .link-parent:focus-visible .link-child,
  .link[data-headlessui-state='active'],
  .link-parent[data-headlessui-state='active'] .link-child,
  .svg-link:focus-visible,
  .svg-link-parent:focus-visible .svg-link-child,
  .svg-link[data-headlessui-state='active'],
  .svg-link-parent[data-headlessui-state='active'] .svg-link-child {
    @apply outline;
    color: var(--link-hover);
  }

  .link[aria-expanded='true'],
  .link[aria-checked='true'],
  .svg-link[aria-expanded='true'],
  .svg-link-parent[aria-checked='true'] .svg-link-child {
    background-color: var(--link-active);
    color: var(--link-active);
  }

  @media (hover: hover) and (pointer: fine) {
    .link:hover,
    .link-parent:hover .link-child,
    .svg-link:hover,
    .svg-link-parent:hover .svg-link-child {
      color: var(--link-hover);
    }

    .link[aria-expanded='true'],
    .link[aria-checked='true'],
    .svg-link[aria-expanded='true'],
    .svg-link-parent[aria-checked='true'] .svg-link-child {
      color: var(--link-active);
    }
  }

  .link:not(:hover):active,
  .link.is-active:not(:hover, .no-active),
  .svg-link:not(:hover):active,
  .svg-link.is-active:not(:hover, .no-active),
  .link-parent:not(:hover):active .link-child,
  .link-parent.is-active:not(:hover, .no-active) .link-child,
  .svg-link-parent:not(:hover):active .svg-link-child,
  .svg-link-parent.is-active:not(:hover, .no-active) .svg-link-child {
    color: var(--link-active);
  }

  /* stylelint-disable no-descending-specificity */
  .link[disabled],
  .link[aria-disabled='true'],
  [aria-disabled='true'] .link,
  .svg-link[disabled],
  .svg-link[aria-disabled='true'],
  [aria-disabled='true'] .svg-link,
  .svg-link[disabled] .svg-link-child,
  .svg-link[aria-disabled='true'] .svg-link-child,
  [aria-disabled='true'] .svg-link .svg-link-child {
    --link-color: theme('colors.black') !important;
    --link-hover: theme('colors.black') !important;
    --link-active: theme('colors.black') !important;
    pointer-events: none;
  }
  /* stylelint-enable no-descending-specificity */
}

@import './tailwind.css';
@import './vars.css';
@import './fonts.css';
@import './scheme.css';
@import './button.css';
@import './cta.css';
@import './container.css';
@import './link.css';
@import './input.css';
@import './textarea.css';
@import './rich-text.css';
@import './figure.css';
@import './section.css';
@import './dropdown.css';
@import './splash.css';
@import './embla.css';
@import './mux.css';
@import './cart.css';
@import './marquee.css';
@import './stockist.css';

@layer base {
  html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-padding-top: var(--header-h);
    scrollbar-color: theme('colors.pink') theme('colors.gray');
    scrollbar-gutter: stable;
    scrollbar-width: auto;
  }

  body {
    background-color: theme('colors.green');
    color: theme('colors.yellow');
    font-family: theme('fontFamily.sans');
  }

  ::selection {
    background: theme('colors.yellow');
    color: theme('colors.green');
  }

  hr {
    appearance: none;
    background-color: currentColor;
    border: 0;
    display: block;
    height: var(--border-w);
    width: 100%;
  }

  a,
  button {
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    -moz-tap-highlight-color: rgb(0 0 0 / 0%);
  }

  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  textarea:focus-visible {
    @apply outline;
  }
}

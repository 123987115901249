@layer components {
  .dropdown {
    background-color: var(--scheme-bg);
    position: relative;

    &.is-open {
      z-index: 3;
    }
  }

  .dropdown-button,
  .dropdown-option {
    align-items: center;
    background-color: var(--btn-bg);
    border: var(--border-w) solid var(--btn-fg);
    border-radius: 0;
    color: var(--btn-fg);
    display: flex;
    height: var(--dropdown-option-h);
    justify-content: center;
    overflow: hidden;
    padding-left: var(--ui-size);
    padding-right: var(--ui-size);
    text-align: center;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;

    &:focus-visible,
    &[data-headlessui-state='active'] {
      background-color: var(--btn-bg-hover);
      color: var(--btn-fg-hover);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    .dropdown-button:hover,
    .dropdown-option:hover {
      background-color: var(--btn-bg-hover);
      color: var(--btn-fg-hover);
    }
  }

  .dropdown-icon {
    --tw-translate-x: 50%;
    --tw-translate-y: -50%;
    display: block;
    height: calc(var(--ui-size) * 0.333);
    position: absolute;
    right: calc(var(--ui-size) * 0.5);
    top: 50%;
    transition: transform theme(transitionDuration.DEFAULT)
      theme(transitionTimingFunction.DEFAULT);
    width: calc(var(--ui-size) * 0.333);
    z-index: 2;
  }

  .dropdown-options {
    height: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: calc(100% - var(--border-w));
    transition: height theme(transitionDuration.DEFAULT)
      theme(transitionTimingFunction.DEFAULT);
    width: 100%;
    z-index: 2;

    .dropdown.is-open & {
      height: var(--dropdown-options-h);
    }
  }

  .dropdown-option {
    border: var(--border-w) solid currentColor;
    border-top: 0;
    pointer-events: auto;

    &:first-child {
      border-top: var(--border-w) solid currentColor;
    }
  }
}

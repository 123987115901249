@layer base {
  @keyframes marquee-scroll {
    0% {
      transform: translate3d(0%, 0%, 0);
    }
    100% {
      transform: translate3d(-100%, 0%, 0);
    }
  }
}

@layer components {
  .marquee {
    display: flex;
    flex-direction: row;
    height: var(--marquee-h);
    overflow: hidden;
    position: relative;
    transform: var(--transform);
    width: var(--width);

    &:hover div {
      animation-play-state: var(--pause-on-hover);
    }

    &:active div {
      animation-play-state: var(--pause-on-click);
    }
  }

  .marquee-overlay,
  .marquee-track,
  .marquee-initial-child-wrap,
  .marquee-child {
    height: 100%;
  }

  .marquee-overlay {
    height: 100%;
    position: absolute;
    width: 100%;

    &::before,
    &::after {
      background-image: linear-gradient(
        to right,
        var(--gradient-color),
        rgb(255, 255, 255, 0)
      );
      content: '';
      height: 100%;
      pointer-events: none;
      position: absolute;
      touch-action: none;
      width: var(--gradient-width);
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }
  }

  .marquee-track,
  .marquee-initial-child-wrap,
  .marquee-child {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .marquee-track {
    animation: marquee-scroll var(--duration) linear var(--delay)
      var(--iteration-count);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
    animation-play-state: var(--play);
    flex: 0 0 auto;
    min-width: var(--min-width);
    will-change: transform;
    z-index: 1;
  }

  .marquee-initial-child-wrap {
    flex: 0 0 auto;
    min-width: auto;
  }

  .marquee-child {
    padding-left: calc(var(--marquee-h) / 2);
    padding-right: var(--marquee-h);
    position: relative;
    transform: var(--transform);
    white-space: nowrap;

    img {
      height: var(--marquee-h);
      max-width: none !important;
      width: auto !important;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cpath fill='%23FFF827' d='M27 57.27c1.26-3.7 3.66-5.7 5.44-6.72.23-.13.45-.25.64-.34 1.28 6.09 3.89 10.26 6.91 10.26s5.64-4.17 6.91-10.26c.19.1.41.21.64.34 1.79 1.03 4.18 3.03 5.44 6.72 1.5 4.39.97 10.03-1.52 16.73h1.34c3.21-6.4 7.47-19.34-5.41-26.78.07-.58.14-1.17.19-1.78 1.71.01 4.23.48 6.61 2.61 3 2.69 4.94 7.27 5.78 13.62 0 .02.01.04.01.05.01.07.03.13.07.19 1.36 2.11 4.85 5.64 12.98 5.64h.24V64.61l-.31.09s-1.26.37-3.09.37c-2.41 0-5.93-.64-9.04-3.69-.04-5.35-1.46-17.2-13.12-18.24.01-.46.02-.91.02-1.37 0-1.25-.11-2.72-.32-4.34l16.73 11.69c6.73 4.7 15.86-.19 15.86-8.47 0-5.68-4.53-10.29-10.11-10.29H46.25c-.11-.56-.23-1.12-.35-1.68 8.55-.88 9.62-9.6 9.66-13.54 2.24-2.16 4.77-2.62 6.49-2.62 1.32 0 2.22.27 2.23.27l.45.14v-2.59h-.35c-6.08 0-8.7 2.66-9.73 4.24-.04.06-.06.13-.08.2 0 .01-.01.03-.01.05-.61 4.64-2.02 7.99-4.2 9.94-1.73 1.56-3.57 1.88-4.8 1.88h-.11C43.17 16.43 39.99 6 39.99 6s-3.17 10.43-5.46 20.65h-.11c-1.23 0-3.07-.33-4.8-1.88-2.18-1.96-3.59-5.3-4.2-9.94 0-.02-.01-.04-.01-.05a.496.496 0 0 0-.08-.2c-1.02-1.58-3.65-4.24-9.73-4.24h-.35v2.59l.45-.14c.04-.01.94-.27 2.23-.27 1.73 0 4.25.46 6.49 2.62.04 3.94 1.11 12.66 9.66 13.54-.12.57-.24 1.13-.35 1.68H10.11C4.53 30.36 0 34.97 0 40.65c0 8.28 9.13 13.17 15.84 8.48l16.73-11.69c-.2 1.62-.32 3.09-.32 4.34 0 .46.01.92.02 1.37-11.67 1.04-13.08 12.9-13.12 18.24-3.11 3.05-6.63 3.69-9.04 3.69-1.83 0-3.08-.37-3.09-.37l-.31-.09V67.56h.24c8.13 0 11.62-3.53 12.98-5.64.04-.06.06-.12.07-.19.01-.02.01-.03.01-.05.83-6.35 2.78-10.94 5.78-13.62 2.38-2.14 4.9-2.6 6.61-2.61.05.6.11 1.2.19 1.78C19.71 54.66 23.97 67.6 27.18 74h1.34C26.03 67.3 25.5 61.67 27 57.27z' /%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: calc(var(--marquee-h) / 2);
    }
  }
}

@layer components {
  .section {
    padding-bottom: var(--gutter-xl);
    padding-top: var(--gutter-xl);
    width: 100%;

    &:first-of-type {
      padding-top: var(--gutter-lg);
    }

    &:last-of-type {
      padding-bottom: calc(var(--gutter-xl) * 2);
    }
  }

  .section.slim {
    padding-bottom: var(--gutter-lg);
    padding-top: var(--gutter-lg);
  }

  .section.full-bleed {
    padding-bottom: 0;
    padding-top: 0;

    &:first-of-type {
      border-top: 0;
    }
  }

  .section:not(.full-bleed).scheme.green + .section.scheme.green,
  .section:not(.full-bleed).scheme.blue + .section.scheme.blue {
    padding-top: 0;
  }
}

@layer components {
  .figure {
    align-items: stretch;
    border: var(--border-w) solid var(--fig-border-outer-color);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    text-align: center;

    &.has-caption {
      height: 100%;
    }

    &.is-borderless {
      --border-w: 0;
    }
  }

  .figure-heading,
  .figure-media,
  .figure-caption,
  .figure-cta {
    width: 100%;
  }

  .figure-heading,
  .figure-media,
  .figure-caption {
    border-bottom: var(--border-w) solid var(--fig-border-inner-color);
    overflow: hidden;
    padding: var(--gutter-sm) calc(var(--gutter-sm) * 1.5);

    &:last-child {
      border-bottom: 0;
    }
  }

  .figure-heading {
    flex: 0 1 auto;
    text-transform: uppercase;
  }

  .figure-media > a,
  .figure-cta > a,
  .figure-cta > button {
    --btn-bg-hover: theme('colors.yellow');
  }

  .figure-media {
    flex: 0 0 auto;
    max-height: calc(var(--screen-h) - var(--header-h));
    position: relative;

    > a {
      background-color: var(--scheme-bg);
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: background-color theme('transitionDuration.DEFAULT')
        theme('transitionTimingFunction.DEFAULT');
      width: 100%;

      &:focus-visible {
        background-color: var(--btn-bg-hover);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--btn-bg-hover);
        }
      }
    }

    img,
    video {
      display: block;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      object-fit: contain;
      object-position: center;
      position: relative;
      z-index: 2;
    }

    mux-player {
      height: 100%;
    }

    .figure.has-enforced-aspect & {
      aspect-ratio: var(--figure-media-aspect, auto);
      padding: 0;

      > *,
      .video,
      .video > * {
        height: 100%;
      }

      img,
      video,
      mux-player {
        bottom: var(--gutter-sm);
        height: calc(100% - (var(--gutter-sm) * 2));
        left: var(--gutter-sm);
        position: absolute;
        right: var(--gutter-sm);
        top: var(--gutter-sm);
        width: calc(100% - (var(--gutter-sm) * 2)) !important;
      }
    }

    .figure.is-full-bleed & {
      padding: 0;

      > *,
      .video,
      .video > * {
        height: 100%;
      }

      img,
      video,
      mux-player {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100% !important;
      }
    }
  }

  .figure-caption {
    flex: 1 1 auto;
    text-align: left;
  }

  .figure-cta {
    background-color: var(--fig-cta-color);
    flex: 0 1 auto;
    height: var(--ui-size);

    > button,
    > a {
      border: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@layer components {
  .stockist {
    #stockist-widget {
      overflow: hidden;
    }

    #stockist-widget .stockist-result-list ul > li {
      border-top-color: var(--scheme-fg);
      font-size: 16px;

      &.stockist-selected {
        border-left-color: var(--color-accent) !important;
      }
    }

    #stockist-widget .stockist-query-entry .stockist-search-button button,
    #stockist-widget .stockist-query-entry .stockist-search-field {
      background-color: var(--btn-bg) !important;
      color: var(--btn-fg) !important;
      height: var(--ui-size-min) !important;
    }

    #stockist-widget .stockist-query-entry .stockist-search-button button {
      width: var(--ui-size-min);
    }

    #stockist-widget .stockist-query-entry .stockist-search-field {
      border: var(--border-w) solid currentColor;
      font-size: 16px;
      height: var(--ui-size-min);

      &::placeholder {
        color: var(--btn-fg) !important;
        font-family: theme('fontFamily.sans');
      }
    }

    #stockist-widget .stockist-map * {
      scrollbar-width: none;
    }

    #stockist-widget .stockist-result {
      background: var(--scheme-bg);
      color: var(--scheme-fg);
      padding: 20px;
    }

    #stockist-widget .gm-style-iw.gm-style-iw-c {
      padding: 0 !important;
    }

    #stockist-widget .gm-style .gm-style-iw-tc::after {
      background-color: var(--scheme-bg) !important;
    }
  }
}

@layer components {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--container-w);
    padding-left: var(--gutter-lg);
    padding-right: var(--gutter-lg);
    width: 100%;
  }

  .container-sm {
    max-width: var(--container-w-sm);
  }

  .container-md {
    max-width: var(--container-w-md);
  }

  .container-lg {
    max-width: var(--container-w-lg);
  }

  .container-xl {
    max-width: var(--container-w-xl);
  }

  .container-2xl {
    max-width: var(--container-w-2xl);
  }

  .container-3xl {
    max-width: var(--container-w-3xl);
  }
}

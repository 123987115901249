.rich-text h1,
.rich-text h5 {
  @apply font-serif;
}

.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h6 {
  @apply font-sans;
}

.rich-text b,
.rich-text strong,
.rich-text h4,
.rich-text h6 {
  @apply font-bold;
}

.rich-text h1 {
  @apply text-h1;
}

.rich-text h2 {
  @apply text-h2;
}

.rich-text h3 {
  @apply text-h3;
}

.rich-text h4 {
  @apply text-h4;
}

.rich-text h5 {
  @apply text-h5;
}

.rich-text h6 {
  @apply text-h6;
}

/* figures */
.rich-text .figure-media {
  padding: 0;
}

.rich-text .figure-caption {
  @apply text-h6;
}

/* lists */
.rich-text ul,
.rich-text ol {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.rich-text li {
  margin-top: var(--list-y);
  padding-left: var(--list-gutter);
  position: relative;
}

.rich-text ol li {
  counter-increment: list-count;
}

.rich-text li:first-child {
  margin-top: 0;
}

.rich-text li::marker {
  display: none;
}

.rich-text li::before {
  display: inline-block;
  left: 0;
  line-height: 1;
  position: absolute;
  top: calc(theme('fontSize.body.1') / 2);
  transform: translateY(-50%);
  vertical-align: middle;
}

.rich-text ul li::before {
  background-color: var(--color-accent);
  border-radius: 100%;
  content: '';
  height: var(--list-bullet-h);
  width: var(--list-bullet-w);
}

.rich-text ol li::before {
  color: var(--color-accent);
  content: counter(list-count) '.';
  font-family: theme('fontFamily.sans');
  font-variant-numeric: tabular-nums;
  font-weight: theme('fontWeight.bold');
  text-align: start;
  text-indent: 0;
  unicode-bidi: isolate;
}

/* vertical rhythm */
.rich-text > *:not(:first-child) {
  margin-top: var(--vert-rich-text);
}

.rich-text > h1:not(:first-child, :nth-child(2)),
.rich-text > h2:not(:first-child, :nth-child(2)),
.rich-text > h3:not(:first-child, :nth-child(2)),
.rich-text > h4:not(:first-child, :nth-child(2)),
.rich-text > h5:not(:first-child, :nth-child(2)),
.rich-text > h6:not(:first-child, :nth-child(2)) {
  margin-top: calc(var(--vert-rich-text) * 2);
}

.rich-text > h1:not(:first-child) + *,
.rich-text > h2:not(:first-child) + *,
.rich-text > h3:not(:first-child) + *,
.rich-text > h4:not(:first-child) + *,
.rich-text > h5:not(:first-child) + *,
.rich-text > h6:not(:first-child) + * {
  margin-top: calc(var(--vert-rich-text) * 1.5);
}

/* elements with double-spacing */
.rich-text > div:not(:first-child),
.rich-text > figure:not(:first-child),
.rich-text > blockquote:not(:first-child),
.rich-text > ul:not(:first-child),
.rich-text > ol:not(:first-child) {
  margin-bottom: calc(var(--vert-rich-text) * 1.75);
  margin-top: calc(var(--vert-rich-text) * 1.75);
}

.rich-text > div:last-child,
.rich-text > figure:last-child,
.rich-text > blockquote:last-child,
.rich-text > ul:last-child,
.rich-text > ol:last-child {
  margin-bottom: 0;
}

/* slim rich text */
.rich-text.slim {
  --vert-rich-text: var(--vert-sm);
  --list-y: var(--vert-sm);
}

.rich-text.slim ul,
.rich-text.slim ol {
  max-width: 100% !important;
}

.rich-text.slim a {
  text-decoration: underline;
}

@screen xs {
  .rich-text ul,
  .rich-text ol {
    max-width: 85%;
  }
}

@screen md {
  /* elements with double-spacing */
  .rich-text > div:not(:first-child),
  .rich-text > figure:not(:first-child),
  .rich-text > blockquote:not(:first-child),
  .rich-text > ul:not(:first-child),
  .rich-text > ol:not(:first-child) {
    margin-bottom: calc(var(--vert-rich-text) * 2);
    margin-top: calc(var(--vert-rich-text) * 2);
  }

  .rich-text > div:last-child,
  .rich-text > figure:last-child,
  .rich-text > blockquote:last-child,
  .rich-text > ul:last-child,
  .rich-text > ol:last-child {
    margin-bottom: 0;
  }
}

@screen lg {
  .rich-text ul,
  .rich-text ol {
    max-width: 75%;
  }
}

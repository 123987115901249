@layer components {
  .splash {
    display: grid;
    text-align: center;
    width: 100%;

    &.is-two-col.has-body-bg {
      .splash-body > *,
      .splash-media {
        aspect-ratio: 1 / 1;
      }
    }

    .splash-body {
      height: 100%;

      > * {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100%;
        padding: var(--gutter-lg);
      }
    }

    .splash-media {
      position: relative;
      z-index: 2;

      > * {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  @screen sm {
    .splash {
      grid-auto-flow: column;
      grid-template-columns: 50% 50%;

      &.is-two-col.has-body-bg {
        .splash-body > * {
          aspect-ratio: auto;
          height: 100%;
        }
      }

      &.is-left.is-two-col,
      &.is-right.is-two-col {
        .splash-media {
          position: sticky;
          top: var(--header-h);
        }
      }

      &.is-right.is-two-col {
        .splash-body {
          grid-column: 1 / 1;
        }

        .splash-media {
          grid-column: 2 / 2;
        }
      }
    }
  }
}
